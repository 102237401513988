
import React from "react"
import Layout from "../../components/Layout"
import { Link } from "gatsby"
import Seo from "../../components/Seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import AllLayouts from "../../components/AllLayouts"

const Home = ({pageContext}) => {  
  const posts  = pageContext.posts
  const tags = pageContext.tags
  const layouts = pageContext.page.acf_pageBuilder.layouts || []
  const options = pageContext.postSearchData.options

  
  return (
    <Layout posts={posts} engine={options} placeholder="Search">
      <Seo
        title="Marco Gomes"
        description="Web Developer"
      />
      
      
      {
        layouts.map((layout, index) => {
          return <AllLayouts key={index} layoutData={layout} />
        })
      }
  

      <section className="article">
        <h2 className="view-all">Latest Udemy Coupon Codes</h2>
        <Link to="blog" className="view-all__link">View all</Link>
        <section>

         { 
          posts.map( (post, index ) => (             
            <Link to={post.uri} key={post.id} >
                <div className="article__item">
                  <div>
                    <GatsbyImage image={ getImage( post.featuredImage.node.imageFile) } alt={post.featuredImage.node.altText} className="article__item__img" />
                  </div>
                  <div>
                    <h2 className="article__item__title"  dangerouslySetInnerHTML={{ __html: post.title }}></h2>
                  </div>
                </div>
            </Link> 
            ))
        } 

        <h2 className="view-all">Tags:</h2><Link to="tags" className="view-all__link">View all</Link>
     
        <div className="tag">
          { tags.map( (tag, index) => (
            
            
            (index < 10) &&
              <Link to={`/tag/${tag.slug}`} key={tag.id} className="tag__link">
              {tag.name}
              </Link> 
            ))
          }
        </div>
        </section>
      </section>


   
    </Layout>
  )
}

export default Home